import "./forms.css";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../user_details";
import edit_icon from "../../assets/edit_icon.svg";
import delete_icon from "../../assets/delete_icon.svg";
import cancel_icon from "../../assets/close_icon.svg";
import * as XLSX from "xlsx";
import axios from "axios";
import SpinnerLoader from '../../helpers/spinner-loader';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function Forms(props) {
 const navigate = useNavigate();
 const [forms, setForms] = useState([]);
 const context = useContext(Context);
 const userDetails = context.user;
 const [deleted_users, setDeletedUsers] = useState(new Set());
 //const [copytext, setCopyText] = useState('');
 const [loading, setLoading] = useState(false);
 const [show, setShow] = useState(false);
 const [downloadSuccess, setDownloadSuccess] = useState(null);

 useEffect(() => {
  console.log(props, "this is initial deleted users", deleted_users);
  return console.log("this is initial deleted users", deleted_users);
 }, [deleted_users]);

 const getFormsList = async () => {
  try {
   const response = await fetch(process.env.REACT_APP_API_URL + "/api/form/list?page=1&limit=10");
   const result = await response.json();

   // Check if result is an object and has a forms property
   if (result && Array.isArray(result.forms)) {
    setForms(result.forms);
   } else {
    console.error("Expected an array of forms, but got:", result ? result.forms : "undefined");
    setForms([]); // Set to empty array to prevent errors
   }
  } catch (error) {
   console.error("Error fetching forms:", error);
   setForms([]); // Optional: Reset forms to empty on error
  }
 };

 useEffect(() => {
  getFormsList();
 }, []);

 const createForm = () => {
  navigate("/CreateForm");
 };

 const handlecopy = (formId) => {
  if (formId) {
   navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/formsdata/submitform/${formId}`);
   alert("URl Copied:", formId);
  } else {
   console.error("Invalid formId:", formId);
  }
 };

 const editForm = (formId) => {
  console.log("Navigating to UpdateForm with formId:", formId);
  navigate(`/UpdateForm/${formId}`);
 };

 const deleteProfile = async (event) => {
  //             // Confirmation step before proceeding with the delete
  // const confirmDelete = window.confirm("Are you sure you want to delete this form?");
  // if (!confirmDelete) {
  //     return; // Exit if user cancels the action
  // }
  let del_users = deleted_users;
  const formId = event.currentTarget.getAttribute("value"); // Get form ID from value attribute

  if (formId) {
   let selected_user = event.target;
   if (selected_user.tagName !== "IMG") {
    selected_user = selected_user.childNodes[0];
   }

   if (selected_user.className === "delete_icon") {
    selected_user.src = cancel_icon;
    selected_user.className = "cancel_icon";
    del_users.add(formId);
   } else {
    selected_user.src = delete_icon;
    selected_user.className = "delete_icon";
    del_users.delete(formId);
   }

   setDeletedUsers(new Set(del_users)); // Update the state with the deleted forms
   console.log("Deleted users:", del_users);
  } else {
   console.error("Form ID not found.");
  }
 };

 const delete_users = async () => {
  for (let formId of deleted_users) {
   try {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/form/delete/" + formId, {
     method: "DELETE",
    });

    if (response.ok) {
     // If the response is OK but doesn't contain JSON (e.g., status 204 No Content)
     const result = await response.text(); // Attempt to get the text response
     if (result) {
      // Only parse JSON if result is not empty
      try {
       const jsonResult = JSON.parse(result);
       console.log("Delete result:", jsonResult);
      } catch (jsonError) {
       console.log("Response is not JSON:", result);
      }
     }
     // Reset the deleted_users set and update the forms list
     setDeletedUsers(new Set());
     await getFormsList();
     navigate("/Forms");
    } else {
     console.error("Error during deletion:", response.statusText);
    }
   } catch (error) {
    console.error("Error deleting user:", error);
   }
  }
 };

 const downloadExcelform = async (formId) => {
  try {
    setLoading((prev) => ({ ...prev, [formId]: true }));
    setDownloadSuccess(true);
  //  const response = await fetch(process.env.REACT_APP_API_URL + "/api/formsdata/submitform/" + formId);
  //  const formdata = await response.json();
  var response,formdata;
  response = await fetch(process.env.REACT_APP_API_URL + "/api/formsdata/submitform/" + formId);
  formdata = await response.json();
  console.log(formdata, " this is the formdata");

   const result = await fetch(process.env.REACT_APP_API_URL + "/api/form/read/" + formId);
   const clientsFormdata = await result.json();
   // Log the clientsForm to inspect its structure
   console.log(clientsFormdata, "this is the clientsForm");
   const formHeaders = clientsFormdata.fields.map((field) => field.field_name);

   // Check if formdata is an array and has at least one item
   if (1) {
    // Prepare headers (field names), including S.No
    const headers = ["S.No", ...formHeaders]; // First header will be S.No

    // Prepare the data for each row, including S.No as the first column
    const data = [
     headers, // First row: field names
     ...formdata.map((item, index) => {
      return [index + 1, ...Object.values(item.data)]; // Add S.No and corresponding data
     }),
    ];
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);     
    
// Make first row bold
for(let i = 0; i < headers.length; i++) {
  const cell = worksheet[XLSX.utils.encode_cell({r: 0, c: i})];
  // Create new style if cell doesnt have a style yet
  if(!cell.s) {cell.s = {};}
  if(!cell.s.font) {cell.s.font = {};}
  // Set bold
  cell.s.font.bold = true;
}
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Form Data");
    // Generate a binary string and trigger a download
    XLSX.writeFile(workbook, "form_data.xlsx");
   } else {
    console.error("No data found for the selected form.");
   }
  } catch (error) {
   console.error("Error downloading Excel form:", error);
   setDownloadSuccess(false);
  }finally {
    setShow(true);
    setLoading((prev) => ({ ...prev, [formId]: false }));
    //setLoading(false);
    setTimeout(() => {
         setShow(false);
    }, 2000);   
  }
 };

 return (
  <div className="w-100 p-5 " >
   <div className="options_container d-flex align-items-center justify-content-end gap-2">
    {deleted_users.size > 0 ? (
     <div onClick={delete_users} className="btn btn-danger">
      Delete
     </div>
    ) : (
     ""
    )}
    <button className="nexus-button-primary-dark" onClick={createForm}>
     + Add Form
    </button>
   </div>

   {/* Table taking full width */}

   <table className="nexus-table-primary-dark-stripped w-100">
    <thead>
     <tr className="text-center">
      <th>Name</th>
      <th>Firm</th>
      <th>Field Names</th>
      <th>Status</th>
      <th>Actions</th>
     </tr>
    </thead>
    <tbody>
     {forms.length > 0 ? (
      forms.map((form) => (
       <tr key={form._id}>
        <td>{form.name || "N/A"}</td>
        <td>{form.client_id?.firm_name || "N/A"}</td>
        <td>
         {Array.isArray(form.fields) && form.fields.length > 0
          ? form.fields.map((field) => field.field_name).join(", ")
          : "No fields available"}
        </td>
        <td>{form.status === 1 ? "Active" : "Inactive"}</td>
        <td>
         <div className="d-inline-flex align-items-center flex-wrap gap-2 mx-auto">
          <button className="nexus-button-primary-dark" onClick={() => handlecopy(form._id)} title="Copy Api">
           <i class="bi bi-copy"></i>
          </button>
          <button className="nexus-button-primary-dark" onClick={() => editForm(form._id)}>
           <i class="bi bi-pencil-square"></i>
          </button>
          <button className="nexus-button-danger" value={form._id} onClick={deleteProfile}>
           <i class="bi bi-trash"></i>
          </button>
          {loading[form._id] ? (
         <div className="d-flex align-items-center justify-content-center " id="form-data-download-loader">
            <SpinnerLoader />
          </div>
          ):(
          <button className="nexus-button-primary-dark" onClick={() => downloadExcelform(form._id)} title="Download Submitted Data">
           <i class="bi bi-download"></i>
          </button>
          )}
          {/* <Offcanvas show={show} onHide={() => {setShow(false) }} placement='end' style={{height: 'fit-content'}}>
             <Offcanvas.Body className='p-3 text-white border border-1 border-white fw-bold d-flex align-items-center bg-danger'>
                  ✘ Download Submitted Data Failed
                 </Offcanvas.Body>
           </Offcanvas> */}
             <Offcanvas show={show} onHide={() => setShow(false)} placement="end" style={{ height: 'fit-content' }}>
              <Offcanvas.Body className={`p-3  border border-2 fw-medium fs-6 bg-opacity-90 d-flex align-items-center bg-white ${downloadSuccess ? ' border-success text-success' : 'border-danger text-danger'}`}>
                {downloadSuccess ? (
                  <>✓ Downloaded Successfully</>
                ) : (
                  <>✘ Failed To Download</>
                )}
              </Offcanvas.Body>
            </Offcanvas>
         </div>
        </td>
       </tr>
      ))
     ) : (
      <tr>
       <td colSpan="5" className="text-center p-5">
        No Forms Available
       </td>
      </tr>
     )}
    </tbody>
   </table>
  </div>
 );
}
